import React from "react"

import Layout from "../components/layout"

const Bio = () => {
  return (
    <Layout>
      <div className="max-w-2xl mx-4 md:mx-auto">
        <p className="text-lg font-bold  mb-2">Education </p>
        <p>Graduate Tbilisi Art academy 1995 2000 </p>
        <p>Post graduate Tbilisi Art academy 2000 2003</p>
        <p> Graduate Munich Art Academy 2007 2011</p>
        <p className="text-lg font-bold mt-4 mb-2">Solo exhibitions</p>
        <p>2021 E.K.E Gallery Madrid.</p>
        <p>2015 Gallery Chardain Tbilisi.</p>
        <p>2008 GALLERY OF GEORGIAN ARTIST UNION.</p>
        <p>TBILISI 2008 GALLERY RACIO. TBILISI 2006</p>
        <p> THE CAUCASIAN HOUSE GALLERY (PERSONAL) PARIS.</p>
        <p> 2005 MODERN RT GALLERY(PERSONAL) TBILISI</p>
        <p className="text-lg font-bold mt-4 mb-2">Group exhibitions</p>
        <p>2022 Baia Gallery Tbilisi</p>
        <p>2021 Baia Gallery Tbilisi</p>
        <p>2019 Tbilisi Print Festival LIFE N STYLE</p>
        <p> 2018 Georgian National Gallery </p>
        <p>2013 Karvasla Art Museum TBILISI</p>
        <p>2011 Kunst Arkaden Munich </p>
        <p>
          2009 ARCHIVIO DI STATO. FLORENCE. (GEORIAN CULTURAL WEEK IN FLORENCE)
        </p>
        <p>2009 GALLERY N9. TBILISI “RED” Exhibition</p>
        <p> 2009 BESHARAT GALLERY. ATLANTA 2008 </p>
        <p>2008 MUNICH ART ACADEMY</p>

        <p> 2007 BERDZENISHVILI’S ART CENTER. TBILISI </p>
        <p>2007 GALLERY UNIVERS TBILISI</p>
        <p>2006 GALLERY ARSI. TBILISI</p>
        <p> 2006 GALLERY KOPALA. TBILISI</p>
        <p> 2005 GEORGIAN DAYS IN JAPAN</p>
        <p> 2004 GALLERY UNIVERSE. TBILISI 2004</p>
        <p> NATIONAL ART GALLERY. TBILISI</p>
        <p> 2003 MODERN ART GALLERY. TBILISI</p>
        <p> 2002 NATIONAL ARTY GALLERY. TBILISI</p>
        <p> 2001 NATIONAL ART GALLERY. TBILISI</p>
        <p> 2000 GALLERY HOBBI. TBILISI </p>
        <p>1998 KARVASLA ART MUSEUM TBILISI ​</p>
      </div>
    </Layout>
  )
}

export default Bio
